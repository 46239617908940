import openOrderIcon from "../../assets/icons/open-order-icon.png";
import orderHistoryIcon from "../../assets/icons/order-history-icon.png";
import tradeIcon from "../../assets/icons/trade-history-icon.png";

import cashIcon from "../../assets/icons/cash-icon.png";
import depositIcon from "../../assets/icons/deposit-icon.png";
import withdrawIcon from "../../assets/icons/withdraw-icon.png";
import transactionIcon from "../../assets/icons/transaction-history.png";

import announcementIcon from "../../assets/icons/announcement-icon.png";
import faqIcon from "../../assets/icons/faq-icon.png";
import contactUsIcon from "../../assets/icons/contact-icon.png";
import verificationIcon from "../../assets/icons/official-verification-icon.png";

import profileIcon from "../../assets/icons/profile-icon.png";
import securityIcon from "../../assets/icons/security-icon.png";
import settingIcon from "../../assets/icons/setting-icon.png";
import apiIcon from "../../assets/icons/api-icon.png";
import logoutIcon from "../../assets/icons/logout-icon.png";

export const navLinkData = (toggleMenu, openMenuIndex) => [
  { name: "Exchange", link: "/exchange" },
  {
    name: "Wallet",
    function: () => toggleMenu(1),
    state: openMenuIndex === 1,
    menu: [
      {
        icon: depositIcon,
        menuName: "Deposit",
        menuLink: "/wallet/deposit",
      },
      {
        icon: withdrawIcon,
        menuName: "Withdraw",
        menuLink: "/wallet/withdraw",
      },
      {
        icon: transactionIcon,
        menuName: "Transaction History",
        menuLink: "/wallet/transaction-history",
      },
    ],
  },
  {
    name: "Orders",
    function: () => toggleMenu(2),
    state: openMenuIndex === 2,
    menu: [
      {
        icon: openOrderIcon,
        menuName: "Open Orders",
        menuLink: "/orders/open-orders",
      },
      {
        icon: orderHistoryIcon,
        menuName: "Order History",
        menuLink: "/orders/order-history",
      },
      {
        icon: tradeIcon,
        menuName: "Trade History",
        menuLink: "/orders/trade-history",
      },
    ],
  },
  {
    function: () => toggleMenu(3),
    state: openMenuIndex === 3,
    name: "Support",
    menu: [
      {
        icon: announcementIcon,
        menuName: "Announcements",
        menuLink: "/support/announcement/all",
      },
      { icon: faqIcon, menuName: "FAQ", menuLink: "/support/FAQ" },
      {
        icon: contactUsIcon,
        menuName: "Contact Us",
        menuLink: "/support/contact-us",
      },
      {
        icon: verificationIcon,
        menuName: "Official Verification",
        menuLink: "/support/official-verification",
      },
    ],
  },
];

export const profileButtonData = (
  dispatch,
  setUserHandler,
  setAccess,
  setRefresh,
  setLoggedIn,
  user
) => [
  // {
  //   icon: profileIcon,
  //   menuName: user?.email ?? 'Email not found',
  //   menuLink: "/profile",
  // },
  // {
  //   icon: verificationIcon,
  //   menuName: "Verification",
  //   menuLink: "/verification",
  // },
  {
    icon: profileIcon,
    menuName: user?.email ?? "Email not found",
    menuLink: "/security",
  },
  {
    icon: apiIcon,
    menuName: "API Management",
    menuLink: "/api-management/getting-start-with-Nio Exchange",
  },
  {
    icon: settingIcon,
    menuName: "Settings",
    menuLink: "/settings",
  },
  {
    icon: logoutIcon,
    menuName: "Logout",
    menuLink: "/sign-in",
    function: () => {
      dispatch(setUserHandler(null));
      setAccess(null);
      setRefresh(null);
      setLoggedIn(false);
    },
  },
];

// export const languages = [
//   { code: "en", label: "English", icon: "🇺🇸" },
//   { code: "es", label: "Spanish", icon: "🇪🇸" },
//   { code: "zh", label: "Chinese", icon: "🇨🇳" },
//   { code: "hi", label: "Hindi", icon: "🇮🇳" },
//   { code: "ar", label: "Arabic", icon: "🇸🇦" },
//   { code: "fr", label: "French", icon: "🇫🇷" },
//   { code: "ru", label: "Russian", icon: "🇷🇺" },
//   { code: "pt", label: "Portuguese", icon: "🇵🇹" },
//   { code: "bn", label: "Bengali", icon: "🇧🇩" },
//   { code: "ur", label: "Urdu", icon: "🇵🇰" },
//   { code: "id", label: "Indonesian", icon: "🇮🇩" },
//   { code: "de", label: "German", icon: "🇩🇪" },
//   { code: "ja", label: "Japanese", icon: "🇯🇵" },
//   { code: "pa", label: "Punjabi", icon: "🇮🇳" },
//   { code: "ms", label: "Malay", icon: "🇲🇾" },
//   { code: "sw", label: "Swahili", icon: "🇰🇪" },
//   { code: "ko", label: "Korean", icon: "🇰🇷" },
//   { code: "tr", label: "Turkish", icon: "🇹🇷" },
//   { code: "it", label: "Italian", icon: "🇮🇹" },
//   { code: "nl", label: "Dutch", icon: "🇳🇱" },
//   { code: "vi", label: "Vietnamese", icon: "🇻🇳" },
//   { code: "th", label: "Thai", icon: "🇹🇭" },
//   { code: "pl", label: "Polish", icon: "🇵🇱" },
//   { code: "he", label: "Hebrew", icon: "🇮🇱" },
//   { code: "ro", label: "Romanian", icon: "🇷🇴" },
// ];

export const languages = [
  { english: "English", native: "English" },
  { english: "Azərbaycanca", native: "Azerbaijani" },
  { english: "Bahasa Indonesia", native: "Indonesian" },
  { english: "Bahasa Melayu", native: "Malay" },
  { english: "Català", native: "Catalan" },
  { english: "Čeština", native: "Czech" },
  { english: "Dansk", native: "Danish" },
  { english: "Deutsch", native: "German" },
  { english: "Español", native: "Spanish" },
  { english: "Français", native: "French" },
  { english: "Hrvatski", native: "Croatian" },
  { english: "Italiano", native: "Italian" },
  { english: "Kiswahili", native: "Swahili" },
  { english: "Lietuvių", native: "Lithuanian" },
  { english: "Magyar", native: "Hungarian" },
  { english: "O'zbek", native: "Uzbek" },
  { english: "Polski", native: "Polish" },
  { english: "Português", native: "Portuguese" },
  { english: "Română", native: "Romanian" },
  { english: "Shqip", native: "Albanian" },
  { english: "Slovenščina", native: "Slovenian" },
  { english: "Slovenský jazyk", native: "Slovak" },
  { english: "Srpski", native: "Serbian" },
  { english: "Suomi", native: "Finnish" },
  { english: "Svenska", native: "Swedish" },
  { english: "Tagalog", native: "Tagalog" },
  { english: "Tiếng Việt", native: "Vietnamese" },
  { english: "Türkçe", native: "Turkish" },
  { english: "Ελληνικά", native: "Greek" },
  { english: "български", native: "Bulgarian" },
  { english: "Монгол", native: "Mongolia" },
  { english: "Русский", native: "Russian" },
  { english: "Українська", native: "Ukrainian" },
  { english: "ქართული", native: "Georgian" },
  { english: "हिन्दी", native: "Hindi" },
  { english: "বাংলা", native: "Bengali" },
  { english: "ภาษาไทย", native: "Thai" },
  { english: "မြန်မာဘာသာ", native: "Burmese" },
  { english: "日本語", native: "Japanese" },
  { english: "简体中文", native: "Chinese(Simplified)" },
  { english: "繁體中文", native: "Chinese(Traditional)" },
  { english: "עִבְרִית", native: "Hebrew" },
  { english: "اُردُو", native: "Urdu" },
  { english: "العربية", native: "Arabic" },
];
