import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCurrencyRedux: { name: "Bitcoin", code: "BTC" },
};

export const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: {
    selectedCurrencyHandler: (state, action) => {
      state.selectedCurrencyRedux = action.payload;
    },
  },
});

export const { selectedCurrencyHandler } = depositSlice.actions;

// export const selectDarkMode = (state) => state.theme.darkMode;

export default depositSlice.reducer;
