import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getStartData: [
    {
      id: 1,
      heading: "Getting started at Nio Exchange Exchange",
      points: [
        "Nio Exchange Exchange Ultimate User Guide",
        "How To Deposit And Withdraw On Nio Exchange Exchange",
        "How to Trade on Nio Exchange Exchange",
      ],
    },
    {
      id: 2,
      heading: "Account and security",
      points: [
        "How To Complete KYC On Nio Exchange Exchange",
        "How to Reset Password",
      ],
    },
    {
      id: 3,
      heading: "Wallet",
      points: [
        "Missing Deposits And Incorrect Token Or Address Information",
        "Nio Exchange Exchange's Withdrawal Fee",
        "How To Solve Withdrawal Issues",
      ],
    },
  ],
  filtered: [],
};

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    // toggleTheme: (state) => {
    //   state.darkMode = !state.darkMode;
    // },
    filterFaqDetailsHandler: (state, action) => {
      const filteredFaqDetail = state.getStartData.flatMap((ele) =>
        ele?.points?.filter((point) => point === action.payload)
      );
      state.filtered = filteredFaqDetail;

      // console.log(state.filtered); 
    },
  },
});

export const { filterFaqDetailsHandler } = faqSlice.actions;

// export const selectDarkMode = (state) => state.theme.darkMode;

export default faqSlice.reducer;
