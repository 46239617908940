import React from 'react'
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
    const navigate = useNavigate();
  return (
    <div className="h-screen relative overflow-hidden">
      <div className="flex flex-col items-center justify-center h-full ">
        <div className="font-bold py-5">
          <span className="text-3xl">401, Unauthorized.</span>
        </div>
        <div className="py-5">
            <span className='text-lg font-medium'>You need to sign in to access this page.{" "}</span>
        </div>
        <div>
          <button
            onClick={() => navigate('/sign-in')}
            type="button"
            className="rounded-md primary-bg md:text-lg sm:text-md text-sm hover:shadow-xl duration-300 py-2 px-7 my-2 text-white "
          >
            Get Authorize!
          </button>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized