import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen relative overflow-hidden">
      <div className="flex flex-col items-center justify-center h-full ">
        <div className="font-bold py-5">
          <span className="text-3xl">404, Page Not Found.</span>
        </div>
        <div>
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="rounded-md primary-bg md:text-lg sm:text-md text-sm hover:shadow-xl duration-300  py-2 px-7 my-2 text-white "
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
