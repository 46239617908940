import React from "react";
import CloseCrossSvg from "../../assets/svg/close-cross";

const CustomModalBox = (props) => {
  const {
    isOpen,
    setIsOpen,
    title,
    children,
    darkMode,
    isTitleAndCloseBtn = true,
  } = props;
  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          className="absolute inset-0 bg-black opacity-50"
          onClick={() => setIsOpen(false)}
        ></div>
        <div
          className={` ${
            darkMode ? "bg-black" : "bg-white"
          } rounded-lg overflow-hidden lg:w-7/12 md:w-9/12 sm:10/12 w-full z-50`}
        >
          <div className=" px-5 max-h-[70vh] overflow-y-auto overflow-x-hidden z-20">
            {isTitleAndCloseBtn && (
              <div className={`sticky px-2 py-5 top-0 flex items-center justify-between gap-5 mb-4 ${darkMode ? "bg-black":"bg-white"}`}>
                <h2 className="text-xl font-bold ">{title}</h2>
                <button onClick={() => setIsOpen(false)}>
                  <CloseCrossSvg />
                </button>
              </div>
            )}
            {children ?? ""}
          </div>
        </div>
      </div>
    )
  );
};

export default CustomModalBox;
