import { combineReducers, configureStore } from "@reduxjs/toolkit";
import themeSlice from "../slice/theme-slice";
import faqSlice from "../slice/faq-slice";
import depositSlice from "../slice/wallet/deposit";
import pathnameSlice from "../slice/pathname-slice";
import userSlice from "../slice/user-slice";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import  pageTitleSlice  from "../slice/page-title-slice";

const rootReducer = combineReducers({
  theme: themeSlice,
  faqDetails: faqSlice,
  deposit: depositSlice,
  pathnameRedux: pathnameSlice,
  userRedux: userSlice,
  pageTitleSlice
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userRedux'], // Add 'userRedux' to persist only the userSlice
};
const persistedReducer = persistReducer(persistConfig, rootReducer); // Assuming you have rootReducer that combines all reducers

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

// export const store = configureStore({
//   reducer: {
//     theme: themeSlice,
//     faqDetails: faqSlice,
//     deposit: depositSlice,
//     pathnameRedux: pathnameSlice,
//     userRedux: userSlice,
//   },
// });
