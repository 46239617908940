import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EyeIconSvg from "../../assets/svg/eye-icon";
import EyeOffIconSvg from "../../assets/svg/eye-off-icon";
import { useAppContext } from "../../context/AppContext";
import baseurl from "../../config";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserHandler } from "../../services/slice/user-slice";

const SignIn = ({ darkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPathRedux = useSelector(
    (state) => state.pathnameRedux.currentPathRedux
  );
  const {
    setAccess,
    setRefresh,
    setLoggedIn,
    loggedIn,
  } = useAppContext();

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    isRemember: false,
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAboveMsg, setShowAboveMsg] = useState({
    msg: "",
    isError: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const timeoutHandler = () =>
    setTimeout(() => {
      setShowAboveMsg({
        msg: "",
        isError: false,
      });
      return;
    }, 2000);

  const handleuser = async (accesstoken) => {
    setLoading(true);
    const aces = accesstoken;

    try {
      const response = await axios.get(
        `${baseurl}accounts/get-user-profile-data/`,
        {
          headers: {
            Authorization: `Bearer ${aces}`,
          },
        }
      );
      if (response.status === 200 || response.status == 201) {
        // setUser(response.data);
        dispatch(setUserHandler(response?.data));
        console.log(response.data, " this is response data");

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err, " this err from user Sign in  in user login");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${baseurl}user-auth/login/`, {
        email: formValues.email,
        password: formValues.password,
      });

      if (response.status === 200 || response.status === 201) {
        // set succesful message
        // userProfile function
        setAccess(response.data.access);
        setRefresh(response.data.refresh);

        const accesstoken = response.data.access;
        handleuser(accesstoken);

        setLoggedIn(true);
        setShowAboveMsg({
          msg: response?.data?.message,
          isError: false,
        });
        timeoutHandler();
        setLoading(false);

        return;
      }
    } catch (err) {
      setShowAboveMsg({
        msg: err?.response?.data?.message,
        isError: true,
      });
      timeoutHandler();
      setLoading(false);
    }
  };
  useEffect(() => {
    if (loggedIn) {
      currentPathRedux
        ? navigate(currentPathRedux)
        : navigate("/wallet/transaction-history");
    }
  }, [loggedIn, navigate, location, currentPathRedux]);

  return (
    <div className={`h-screen bg-transitions ${!darkMode && "bg-[#fafafa]"}`}>
      <div className=" grid md:grid-cols-2 grid-cols-1 pt-7">
        {/* First Grid */}
        <div className="lg:px-20 md:px-10 px-6 md:pt-14 pt-5 flex justify-center ">
          <div className="lg:w-8/12 md:w-10/12 w-full ">
            <div className="mb-4  relative">
              <span className="text-4xl font-bold primary-color">Sign In</span>
              {showAboveMsg?.msg && (
                <div className="mt-1">
                  <span
                    className={` px-3 py-1 text-sm rounded-md  font-medium ${
                      showAboveMsg?.isError
                        ? "text-red-500  border border-red-700"
                        : "text-green-500  border border-green-700 "
                    }`}
                  >
                    {showAboveMsg?.msg}
                  </span>
                </div>
              )}
            </div>

            <form
              className=" flex flex-col gap-1"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="flex flex-col gap-1">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className={`px-2 py-3 text-sm bg-transitions border border-[#999999]  rounded-md  outline-none ${
                    darkMode ? "bg-[#2E2E2E] text-white" : "bg-white"
                  }`}
                  placeholder="Email Address"
                  required
                  values={formValues.email}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="password">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className={`px-2 py-3 text-sm bg-transitions border border-[#999999]  rounded-md  outline-none ${
                    darkMode ? "bg-[#2E2E2E] text-white" : "bg-white"
                  }`}
                  placeholder="Password"
                  required
                  values={formValues.password}
                  onChange={handleChange}
                />
                <div
                  className="absolute  top-10 cursor-pointer right-2 w-6 "
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOffIconSvg /> : <EyeIconSvg />}
                </div>
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    className="h-4 w-4"
                    name="rememberMe"
                    id="rememberMe"
                  />
                  <label htmlFor="rememberMe" className="cursor-pointer">Remember me</label>
                </div>
              </div>
              <button
                type="submit"
                className={`text-white rounded md:text-[18px] sm:text-md text-sm bg-transitions py-3 px-7 my-1 font-medium uppercase ${
                  loading ? "bg-gray-600" : "primary-bg"
                }`}
                // onClick={(event) => handleSubmit(event)}
                disabled={loading}
              >
                {loading ? "Loading..." : "Sign In"}
              </button>
            </form>
            <Link
              to="/forgot-password"
              className=" primary-color underline text-[18px]"
            >
              Forget Password?
            </Link>
            <div className="flex gap-2 mt-5">
              <span className="text-[#999999]">Don't have an account? </span>
              <Link to="/sign-up" className="underline primary-color ">
                Sign Up
              </Link>
            </div>
          </div>
        </div>
        {/* Second Grid */}
        <div className="md:flex hidden justify-center items-center ">
          <div className="">
            <img src="/iphone-img.png" alt="" />
          </div>
        </div>
      </div>

      {/* <PageLoader/> */}
    </div>
  );
};

export default SignIn;
