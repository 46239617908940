import "./App.css";
import React, { Suspense, lazy, useEffect } from "react"; // Import useContext
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import Footer from "./components/footer";
// import Profile from "./pages/profile";
import PageNotFound from "./components/page-not-found";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "./services/slice/theme-slice";

import AuthGuard from "./auth";
import PageLoader from "./components/loader";

const HomePage = lazy(() => delayForDemo(import("./pages/home-page")));
const Header = lazy(() => delayForDemo(import("./components/header")));
const SignIn = lazy(() => delayForDemo(import("./pages/sign-in")));
const SignUp = lazy(() => delayForDemo(import("./pages/sign-up")));
const Exchange = lazy(() => delayForDemo(import("./pages/exchange")));
const ForgotPassword = lazy(() =>
  delayForDemo(import("./pages/forgot-password"))
);
const VerifyCode = lazy(() => delayForDemo(import("./pages/verify-code")));
const VerifyOtp = lazy(() => delayForDemo(import("./pages/verify-otp")));
const ResetPassword = lazy(() =>
  delayForDemo(import("./pages/reset-password"))
);
const ChangePassword = lazy(() =>
  delayForDemo(import("./pages/change-password"))
);
// const Verification = lazy(() => delayForDemo(import("./pages/verification")));
const Security = lazy(() => delayForDemo(import("./pages/security")));
const Settings = lazy(() => delayForDemo(import("./pages/settings")));
const ContactUs = lazy(() =>
  delayForDemo(import("./pages/support/contact-us"))
);
const OfficialVerification = lazy(() =>
  delayForDemo(import("./pages/support/official-verification"))
);
const FAQ = lazy(() => delayForDemo(import("./pages/support/faq")));
const FaqDetails = lazy(() =>
  delayForDemo(import("./pages/support/faq/faq-details"))
);
const AnnouncementAll = lazy(() =>
  delayForDemo(import("./pages/support/announcements/all"))
);
const AnnouncementNews = lazy(() =>
  delayForDemo(import("./pages/support/announcements/news"))
);
const AnnouncementEvents = lazy(() =>
  delayForDemo(import("./pages/support/announcements/events"))
);
const AnnouncementListings = lazy(() =>
  delayForDemo(import("./pages/support/announcements/listings"))
);
const AnnouncementIEOs = lazy(() =>
  delayForDemo(import("./pages/support/announcements/IEOs"))
);
const AnnouncementMaintenance = lazy(() =>
  delayForDemo(import("./pages/support/announcements/maintain"))
);

const TradingFee = lazy(() => delayForDemo(import("./pages/trading-fee")));
const PrivacyPolicy = lazy(() =>
  delayForDemo(import("./pages/privacy-policy"))
);
const TermsAndConditions = lazy(() =>
  delayForDemo(import("./pages/terms-and-condition"))
);
const GettingStartWithAltX = lazy(() =>
  delayForDemo(import("./pages/api-management/getting-start-alt-x"))
);
const UseWebsocketApi = lazy(() =>
  delayForDemo(import("./pages/api-management/how-to-use-websocket-api"))
);
const ApiAuthorization = lazy(() =>
  delayForDemo(import("./pages/api-management/authorization"))
);
const ErrorCode = lazy(() =>
  delayForDemo(import("./pages/api-management/error-code"))
);
const ApiRateLimits = lazy(() =>
  delayForDemo(import("./pages/api-management/rate-limit"))
);
const ManageApi = lazy(() =>
  delayForDemo(import("./pages/api-management/manage-api"))
);

const ApplyForListing = lazy(() =>
  delayForDemo(import("./pages/apply-for-listing"))
);
const ListingForm = lazy(() =>
  delayForDemo(import("./pages/apply-for-listing/listing-form"))
);
const Balance = lazy(() => delayForDemo(import("./pages/wallet/balance")));
const Deposit = lazy(() => delayForDemo(import("./pages/wallet/deposit")));
const Withdraw = lazy(() => delayForDemo(import("./pages/wallet/withdraw")));
const OpenOrders = lazy(() =>
  delayForDemo(import("./pages/orders/open-orders"))
);
const OrderHistory = lazy(() =>
  delayForDemo(import("./pages/orders/order-history"))
);
const TradeHistory = lazy(() =>
  delayForDemo(import("./pages/orders/trade-history"))
);
const DepositAndWithdrawalFees = lazy(() =>
  delayForDemo(import("./pages/deposit-and-withdrawal-status"))
);

const RareFooter = lazy(() =>
  delayForDemo(import("./components/footer/rare-footer"))
);

function delayForDemo(promise) {
  return new Promise((resolve) => {
    setTimeout(resolve, 800);
  }).then(() => promise);
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  function handleClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    handleClick(); // Scroll to the top of the page
  }, [pathname]);

  return null; // This component doesn't render anything
};

function App() {
  const darkMode = true;
  const dispatch = useDispatch();

  const toggleThemeHandler = () => {
    dispatch(toggleTheme());
  };

  return (
    <div className={`App bg-transitions   ${darkMode ? "dark " : "dark "}`}>
      <Router>
        <Suspense fallback={<PageLoader />}>
          <ScrollToTop />
          <Header darkMode={darkMode} toggleTheme={toggleThemeHandler} />
          <div className="min-h-[80vh]">
            <Routes>
              <Route
                path="/"
                element={
                  <HomePage darkMode={darkMode} toggleTheme={toggleTheme} />
                }
              />
              {/* Nav Links Route */}

              <Route
                path="/support/announcement/all"
                element={<AnnouncementAll darkMode={darkMode} />}
              />
              <Route
                path="/support/announcement/news"
                element={<AnnouncementNews darkMode={darkMode} />}
              />
              <Route
                path="/support/announcement/events"
                element={<AnnouncementEvents darkMode={darkMode} />}
              />
              <Route
                path="/support/announcement/listings"
                element={<AnnouncementListings darkMode={darkMode} />}
              />
              <Route
                path="/support/announcement/IEOs"
                element={<AnnouncementIEOs darkMode={darkMode} />}
              />
              <Route
                path="/support/announcement/maintenance"
                element={<AnnouncementMaintenance darkMode={darkMode} />}
              />

              <Route
                path="/support/faq"
                element={<FAQ darkMode={darkMode} />}
              />
              <Route
                path="/support/faq/details"
                element={<FaqDetails darkMode={darkMode} />}
              />
              <Route
                path="/support/contact-us"
                element={<ContactUs darkMode={darkMode} />}
              />
              <Route
                path="/support/official-verification"
                element={<OfficialVerification darkMode={darkMode} />}
              />
              {/* Nav Links Route */}
              <Route path="/sign-in" element={<SignIn darkMode={darkMode} />} />
              <Route path="/sign-up" element={<SignUp darkMode={darkMode} />} />
              <Route
                path="/forgot-password"
                element={<ForgotPassword darkMode={darkMode} />}
              />
              <Route
                path="/reset-password"
                element={<ResetPassword darkMode={darkMode} />}
              />

              <Route
                path="/verify-code"
                element={<VerifyCode darkMode={darkMode} />}
              />
              <Route
                path="/verify-otp"
                element={<VerifyOtp darkMode={darkMode} />}
              />

              {/*Protected Routes  */}
              {/* <Route
                path="/profile"
                element={
                  <AuthGuard>
                    <Profile darkMode={darkMode} />{" "}
                  </AuthGuard>
                }
              />
              <Route
                path="/verification"
                element={
                  <AuthGuard>
                    <Verification darkMode={darkMode} />
                  </AuthGuard>
                }
              /> */}
              <Route
                path="/security"
                element={
                  <AuthGuard>
                    <Security darkMode={darkMode} />
                  </AuthGuard>
                }
              />
              <Route
                path="/change-password"
                element={
                  <AuthGuard>
                    <ChangePassword darkMode={darkMode} />
                  </AuthGuard>
                }
              />
              <Route
                path="/settings"
                element={
                  <AuthGuard>
                    <Settings darkMode={darkMode} />
                  </AuthGuard>
                }
              />
              <Route
                path="/api-management/getting-start-with-Nio Exchange"
                element={<GettingStartWithAltX darkMode={darkMode} />}
              />
              <Route
                path="/api-management/use-websocket-api"
                element={<UseWebsocketApi darkMode={darkMode} />}
              />
              <Route
                path="/api-management/authorization"
                element={<ApiAuthorization darkMode={darkMode} />}
              />
              <Route
                path="/api-management/error-codes"
                element={<ErrorCode darkMode={darkMode} />}
              />
              <Route
                path="/api-management/rate-limits"
                element={<ApiRateLimits darkMode={darkMode} />}
              />
              <Route
                path="/api-management/managing-api"
                element={<ManageApi darkMode={darkMode} />}
              />

              <Route
                path="/wallet/deposit"
                element={
                  <AuthGuard>
                    <Deposit darkMode={darkMode} />
                  </AuthGuard>
                }
              />
              <Route
                path="/wallet/transaction-history"
                element={
                  <AuthGuard>
                    <Balance darkMode={darkMode} />
                  </AuthGuard>
                }
              />
              <Route
                path="/wallet/withdraw"
                element={
                  <AuthGuard>
                    <Withdraw darkMode={darkMode} />
                  </AuthGuard>
                }
              />
              <Route
                path="/orders/open-orders"
                element={
                  <AuthGuard>
                    <OpenOrders darkMode={darkMode} />
                  </AuthGuard>
                }
              />
              <Route
                path="/orders/order-history"
                element={
                  <AuthGuard>
                    <OrderHistory darkMode={darkMode} />
                  </AuthGuard>
                }
              />
              <Route
                path="/orders/trade-history"
                element={
                  <AuthGuard>
                    <TradeHistory darkMode={darkMode} />
                  </AuthGuard>
                }
              />

              {/*Protected Routes  */}

              <Route path="*" element={<PageNotFound />} />

              {/* footer links */}
              <Route
                path="/trading-fee"
                element={<TradingFee darkMode={darkMode} />}
              />
              <Route
                path="/deposit-and-withdrawal-fees"
                element={<DepositAndWithdrawalFees darkMode={darkMode} />}
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions darkMode={darkMode} />}
              />
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy darkMode={darkMode} />}
              />
              <Route
                path="/apply-for-listing"
                element={<ApplyForListing darkMode={darkMode} />}
              />
              <Route
                path="/apply-for-listing/apply-listing-form"
                element={<ListingForm darkMode={darkMode} />}
              />
              <Route
                path="/exchange"
                element={<Navigate to="/exchange/BTC-USDT" />}
              />
              <Route
                path="/exchange/:pair"
                element={<Exchange darkMode={darkMode} />}
              />
            </Routes>
          </div>
          {/* Conditionally render Footer */}
          {!window.location.pathname.startsWith("/exchange") && (
            <Footer darkMode={darkMode} />
          )}
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
