import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPathRedux: "",
};

export const pathnameSlice = createSlice({
  name: "pathname",
  initialState,
  reducers: {
    pathnameHandler: (state, action) => {
      state.currentPathRedux = action.payload;
    },
  },
});

export const { pathnameHandler } = pathnameSlice.actions;

export default pathnameSlice.reducer;
