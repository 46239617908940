import instagramIcon from "../../assets/icons/insta-icon.png";
import fbIcon from "../../assets/icons/fb-icon.png";
import twitterIcon from "../../assets/icons/twitter-icon.png";
import chatIcon from "../../assets/icons/chat-icon.png";
import linkedinIcon from "../../assets/icons/linkedin-icon.png";

export const socialMediaIcon = [
  { icon: instagramIcon, link: "#" },
  { icon: fbIcon, link: "#" },
  { icon: twitterIcon, link: "#" },
  { icon: chatIcon, link: "#" },
  { icon: linkedinIcon, link: "#" },
];

export const footerAboutData = [
  { id: 1, title: "Trading Fee", link: "/trading-fee" },
  {
    id: 2,
    title: "Deposit & Withdrawal Fees",
    link: "/deposit-and-withdrawal-fees",
  },
  { id: 3, title: "Apply for Listing", link: "/apply-for-listing" },
  { id: 4, title: "Terms and Conditions", link: "/terms-and-conditions" },
  { id: 5, title: "Privacy Policy", link: "/privacy-policy" },
];
export const footerSupportData = [
  { id: 1, title: "Announcements", link: "support/announcement/all" },
  { id: 2, title: "FAQ", link: "support/FAQ" },
  { id: 3, title: "Contact us", link: "support/contact-us" },
  // { id: 4, title: "Deposit & Withdrawals", link: "" },
  {
    id: 5,
    title: "Official Verification",
    link: "support/official-verification",
  },
];

export const languages = [
  { code: "en", label: "English", icon: "🇺🇸" },
  { code: "es", label: "Spanish", icon: "🇪🇸" },
  { code: "zh", label: "Chinese", icon: "🇨🇳" },
  { code: "hi", label: "Hindi", icon: "🇮🇳" },
  { code: "ar", label: "Arabic", icon: "🇸🇦" },
  { code: "fr", label: "French", icon: "🇫🇷" },
  { code: "ru", label: "Russian", icon: "🇷🇺" },
  { code: "pt", label: "Portuguese", icon: "🇵🇹" },
  { code: "bn", label: "Bengali", icon: "🇧🇩" },
  { code: "ur", label: "Urdu", icon: "🇵🇰" },
  { code: "id", label: "Indonesian", icon: "🇮🇩" },
  { code: "de", label: "German", icon: "🇩🇪" },
  { code: "ja", label: "Japanese", icon: "🇯🇵" },
  { code: "pa", label: "Punjabi", icon: "🇮🇳" },
  { code: "ms", label: "Malay", icon: "🇲🇾" },
  { code: "sw", label: "Swahili", icon: "🇰🇪" },
  { code: "ko", label: "Korean", icon: "🇰🇷" },
  { code: "tr", label: "Turkish", icon: "🇹🇷" },
  { code: "it", label: "Italian", icon: "🇮🇹" },
  { code: "nl", label: "Dutch", icon: "🇳🇱" },
  { code: "vi", label: "Vietnamese", icon: "🇻🇳" },
  { code: "th", label: "Thai", icon: "🇹🇭" },
  { code: "pl", label: "Polish", icon: "🇵🇱" },
  { code: "he", label: "Hebrew", icon: "🇮🇱" },
  { code: "ro", label: "Romanian", icon: "🇷🇴" },
];