import React, { createContext, useState, useContext } from 'react';

// Create a new context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  // State to manage user authentication data
  const [user, setUserState] = useState(() => localStorage.getItem('user'));
  const [access, setAccessState] = useState(() => localStorage.getItem('access'));
  const [refresh, setRefreshState] = useState(() => localStorage.getItem('refresh'));
  const [loggedIn, setLoggedInState] = useState(() => localStorage.getItem('loggedIn') === 'true');
  const [realuser, setRealUserState] = useState(() => localStorage.getItem('realuser'));



  const setRealUser = (value) => { 
    console.log("Real User is being changed", value);
    localStorage.setItem('realuser', value);
    setRealUser(value);


  }
  const setUser = (value) => {
    console.log("User is being set:", value); // Logging user value
    localStorage.setItem('user', value);
    setUserState(value);
  };

  const setAccess = (value) => {
    console.log("Access is being set:", value); // Logging access value
    localStorage.setItem('access', value);
    setAccessState(value);
  };

  const setRefresh = (value) => {
    console.log("Refresh is being set:", value); // Logging refresh value
    localStorage.setItem('refresh', value);
    setRefreshState(value);
  };

  const setLoggedIn = (value) => {
    console.log("LoggedIn is being set:", value); // Logging loggedIn value
    localStorage.setItem('loggedIn', value ? 'true' : 'false');
    setLoggedInState(value);
  };

  return (
    <AppContext.Provider value={{
      user, 
      setUser,
      access,
      setAccess,
      refresh,
      setRefresh,
      loggedIn,
      setLoggedIn, 
      setRealUser, 
      realuser
    }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the context
export const useAppContext = () => {
  return useContext(AppContext);
};
