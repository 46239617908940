import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "Getting Started with Nio Exchange API",
};

export const pageTitleSlice = createSlice({
  name: "title",
  initialState,
  reducers: {
    setPageTitleHandler: (state, action) => {
      
      state.title = action.payload;
    },
  },
});

export const { setPageTitleHandler } = pageTitleSlice.actions;

export default pageTitleSlice.reducer;
