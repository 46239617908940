import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { footerAboutData, footerSupportData, socialMediaIcon } from "./data";
import CustomModalBox from "../modal";
import ArrowDropdown from "../../assets/svg/arrow-dropdown";
import { languages } from "../header/data";

const Footer = ({ darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectLanguage, setSelectedLanguage] = useState();
  const [showApiDocumentation, setShowApiDocumentation] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathsToCheck = [
      "/api-management/getting-start-with-Nio Exchange",
      "/api-management/use-websocket-api",
      "/api-management/authorization",
      "/api-management/error-codes",
      "/api-management/rate-limits",
      "/api-management/managing-api",
      "/sign-in",
      "/sign-up",
      "/forgot-password",
      "/reset-password",
      "/change-password",
      "/verify-code",
      "/verify-otp",
    ];

    setShowApiDocumentation(pathsToCheck.includes(location.pathname));
  }, [location.pathname]);

  return (
    !showApiDocumentation && (
      <div className="flex justify-center ">
        <div
          className={`md:w-11/12 relative overflow-hidden bg-transitions pt-20 ${
            darkMode ? "bg-[#282828]" : ""
          }`}
        >
          <div className="grid grid-cols-12 lg:gap-2 md:gap-4 gap-6 md:px-0 px-3 relative text-[15px] z-10">
            {/* logo */}
            <div className="lg:col-span-3 md:col-span-6 col-span-12 flex flex-col items-center justify-center gap-8 ">
              <div>
                <img src="/alt-x-logo.png" className="h-24" alt="" />
              </div>

              <div className=" sm:w-8/12 w-10/12 p-3 flex flex-col gap-2 rounded-md primary-bg text-white">
                Are you on the coin project team? Please inquire about the coin
                listing.
                <button
                  onClick={() => navigate("/apply-for-listing")}
                  className="w-full bg-white py-2 rounded-md primary-color font-medium"
                >
                  Apply for IEO/Listing
                </button>
              </div>
            </div>
            {/* About */}
            <div className="lg:col-span-2 md:col-span-6 sm:col-span-6 col-span-12 z-10 ">
              <div className="mb-4">
                <span className=" font-medium text-lg">About</span>
              </div>
              <div className="flex flex-col gap-3">
                {footerAboutData?.map((ele) => (
                  <React.Fragment key={ele?.id}>
                    <Link className="hover:underline" to={ele?.link}>
                      {ele?.title}
                    </Link>
                  </React.Fragment>
                ))}
              </div>
            </div>
            {/* products */}
            <div className="lg:col-span-2 sm:col-span-6 col-span-12 z-10">
              <div className="mb-4">
                <span className=" font-medium text-lg">Products</span>
              </div>
              <div className="flex flex-col gap-3 ">
                <Link to="/exchange" className="hover:underline">
                  Exchange
                </Link>
              </div>
            </div>
            {/* tools */}
            <div className="lg:col-span-2 sm:col-span-6 col-span-12  z-10">
              <div className="mb-4">
                <span className=" font-medium text-lg">Tool</span>
              </div>
              <div className="flex flex-col gap-3">
                {/* {footerSupportData?.map((ele) => (
                  <React.Fragment key={ele?.id}>
                    <Link className="hover:underline" to={ele?.link}>
                      {ele?.title}
                    </Link>
                  </React.Fragment>
                ))} */}
                <Link
                  to="/api-management/getting-start-with-Nio Exchange"
                  className="hover:underline"
                >
                  Api Documentation
                </Link>
              </div>
            </div>
            {/* Support */}
            <div className="lg:col-span-3 sm:col-span-6 col-span-12  z-10">
              <div className="mb-4">
                <span className=" font-medium text-lg">Support</span>
              </div>
              <div className="flex flex-col gap-3">
                {footerSupportData?.map((ele) => (
                  <React.Fragment key={ele?.id}>
                    <Link className="hover:underline" to={ele?.link}>
                      {ele?.title}
                    </Link>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 lg:gap-2 md:gap-4 gap-6 md:px-0 px-3 relative text-[15px] z-10 py-10">
            <div className="lg:col-span-3 sm:col-span-6 col-span-12 flex flex-col items-center justify-center gap-8 "></div>
            {/* App Download */}
            <div className="lg:col-span-2  sm:col-span-6 col-span-12 z-10 lg:block hidden ">
              <div className="mb-4">
                <span className=" font-medium text-lg">App Download</span>
              </div>
              <div className="flex flex-col gap-3">
                <Link className="hover:underline" to="#">
                  Android
                </Link>
                <Link className="hover:underline" to="#">
                  iOS
                </Link>
              </div>
            </div>
            {/* Follow Us */}
            <div className="lg:col-span-4 sm:col-span-6 col-span-12  z-10">
              <div className="mb-4">
                <span className=" font-medium text-lg">Follow Us</span>
              </div>

              <div className="flex gap-2 sm:justify-start justify-center">
                {socialMediaIcon?.map((ele, index) => (
                  <Link to={ele?.link} className="" key={index}>
                    <img src={ele?.icon} className="h-6 rounded-full" alt="" />
                  </Link>

                ))}
              </div>
            </div>
            {/* Langugage */}
            <div className="lg:col-span-3 sm:col-span-6 col-span-12 z-10">
              <div className="mb-4">
                <span className=" font-medium text-lg">Language</span>
              </div>
              <div className="flex flex-col gap-3 ">
                <button
                  onClick={() => setIsOpen(true)}
                  type="button"
                  className="py-4 px-5 w-44 capitalize font-medium hover:shadow-md  rounded border flex justify-between items-center text-center gap-2 "
                >
                  {/* <GlobalWorldSvg /> */}
                  {selectLanguage?.english ?? "English"}
                  <ArrowDropdown fill="black" />
                </button>
              </div>
            </div>
          </div>
          <div className="mt-6 mb-3 flex items-center justify-center">
            <p className="font-bold text-center text-sm">
              Copyright © 2023 Nio Exchange Company Ltd. All rights reserved.
            </p>
          </div>

          {isOpen && (
            <CustomModalBox
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title="Language"
              darkMode={darkMode}
            >
              <div className="flex flex-wrap ">
                {languages.map((ele) => (
                  <div
                    onClick={() => {
                      setSelectedLanguage(ele);
                      setIsOpen(false);
                    }}
                    className={`p-4 flex md:w-1/4 hover:text-[#6456d2] hover:font-medium ${
                      darkMode ? "hover:bg-[#282828]" : "hover:bg-[#f5f4fc]"
                    }`}
                  >
                    <p className="flex  items-center cursor-pointer text-sm">
                      {ele?.english}
                      <span className="px-1 text-[10px]">{ele?.native}</span>
                    </p>
                  </div>
                ))}
              </div>
            </CustomModalBox>
          )}
        </div>
      </div>
    )
  );
};

export default Footer;
