import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserHandler: (state, action) => {
      console.log(state);
      state.user = action.payload;
    },
  },
});

export const { setUserHandler } = userSlice.actions;

export default userSlice.reducer;
