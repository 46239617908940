import { useEffect, useLayoutEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import Unauthorized from "../components/unauthorized";
import { useDispatch } from "react-redux";
import { pathnameHandler } from "../services/slice/pathname-slice";
import SignIn from "../pages/sign-in"

const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { loggedIn } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  // const [showUnauthorized, setShowUnauthorized] = useState(false);

  useLayoutEffect(() => {
    if (!loggedIn) {
      // setShowUnauthorized(true);
      navigate("/sign-in");
      dispatch(pathnameHandler(location?.pathname));
    }  
  }, [loggedIn, location?.pathname, dispatch, navigate]);

  return loggedIn ? children : <SignIn/>;
};

export default AuthGuard;
